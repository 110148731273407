<template>
  <section :id="$options.name" class="policies">
    <div v-for="(hotel, hotel_index) in hotels" :key="_uid+'policies'+hotel_index">
      <h2 class="policies__title" v-html="'Leggi prima di prenotare'" />
      <div class="policies__check">
        <div class="policies__check-wrap">
          <div class="policies__check-content">
            <div class="policies__check__element">
              <vb-icon class="policies__check-icon policies__check-icon--in" :color="'053B95'" :name="'logout'" :size="14" />
              <strong class="policies__check-label semibold" v-html="'Arrivo:'" />
              <div class="policies__check-hours">
                <span v-if="hotel.hotel_data.checkin_checkout_times.checkin_from !== ''" v-html="'dalle ' + hotel.hotel_data.checkin_checkout_times.checkin_from" />
                <span v-if="hotel.hotel_data.checkin_checkout_times.checkin_to !== ''" v-html="' alle ' + hotel.hotel_data.checkin_checkout_times.checkin_to" />
              </div>
            </div>
            <div class="policies__check__element">
              <vb-icon class="policies__check-icon policies__check-icon--out" :color="'053B95'" :name="'logout'" :size="14" />
              <strong class="policies__check-label semibold" v-html="'Partenza:'" />
              <div class="policies__check-hours">
                <span v-if="hotel.hotel_data.checkin_checkout_times.checkout_from !== ''" v-html="'dalle ' + hotel.hotel_data.checkin_checkout_times.checkout_from" />
                <span v-if="hotel.hotel_data.checkin_checkout_times.checkout_to !== ''" v-html="' alle ' + hotel.hotel_data.checkin_checkout_times.checkout_to" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <vb-tabs v-if="hotel.hotel_data.hotel_policies.length" :vb_options="{vertical: !is_mobile()}">
        <b-tab
            class="policies__tab"
            v-for="(policy, policy_index) in hotel.hotel_data.hotel_policies" v-bind:key="_uid + policy_index"
            :active="policy.type === 'POLICY_CHILDREN'"
        >

          <template #title>
            <span v-if="policy.type === 'POLICY_CHILDREN'" v-html="'Bambini e letti'" />
            <span v-else>{{policy.name | lowercase}}</span>
          </template>

          <div class="policies__tab-content">
            <p class="policies__tab-title">{{policy.name | uppercase}}</p>
            <span
                class="policies__tab-text"
                v-if="policy.content !== '' && policy.type === 'POLICY_CHILDREN'"
                v-html="policy.content.replaceAll('.', '.<br>')"
            />
            <span class="policies__tab-text" v-else-if="policy.content !== ''" v-html="policy.content" />
          </div>
        </b-tab>
      </vb-tabs>

      <!-- sezione tabelle culle e letti aggiuntivi -->
      <div class="policies__conditions">
        <p class="policies__conditions_title" v-html="'Condizioni per culle e letti aggiuntivi'" />
        <p class="policies__conditions_subtitle" v-html="'Qualsiasi tipo di letto supplementare o culla è disponibile su richiesta e previa conferma da parte della struttura.'" />
        <cots-extrabeds class="policies__cots-extrabeds" :hotel="hotel.hotel_data" />
      </div>
    </div>
  </section>
</template>

<script>
import CotsExtraBeds from '@booking/components/blocks/cots-extrabeds.vue'

export default {
  name: 'policies',

  components: {
    'cots-extrabeds': CotsExtraBeds,
  },

  props: {
    hotels: Array,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .policies {
    @extend %section;
    @extend %section_margin;

    &__title {
      @extend %section__title;
    }

    &__check {
      margin-bottom: $grid-gutter-width;

      &-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      &__element {
        display: flex;
        align-items: center;

        &:first-child {
          margin-right: 16px;
        }
      }

      &-icon {
        margin-right: 5px;
        margin-top: 2px;

        &--in {
          transform: rotate(180deg)
        }
      }

      &-hours {
        margin-left: 5px;
      }
    }

    &__tab {
      line-height: $font-size-base * 2;

      &-title {
        font-size: $font-size-lg;
        font-weight: $vb-font-weight-semibold;
        margin-bottom: 10px;
      }
    }

    &__conditions {
      margin-top: $grid-gutter-width;
      background-color: $white;
      padding: 20px;
      border-radius: 10px;

      &_title {
        margin-bottom: 0;
        font-size: $font-size-lg;
        font-weight: $vb-font-weight-semibold;
      }

      &_subtitle {
        margin-bottom: 0;
      }
    }

    &__cots-extrabeds {
      margin-top: $grid-gutter-width/2;

      &:empty {
        display: none;
      }
    }

    ::v-deep {
      .cots-extrabeds {

        &__item{
          flex-basis: 100%;

          @include media-breakpoint-up(lg){
            flex-basis: 50%;
          }

          @include media-breakpoint-up(xl){
            flex-basis: 33.33%;
          }
        }
      }
    }
  }

</style>
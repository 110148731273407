<template>
  <div class="detail-view">
    <b-container>

      <booking-engine @search="() => { this.$router.push({ name: 'results' }) }"/>

      <section class="section section--loader" v-if="searching">
        <loader size="large" :show_timing_slot="true" />
      </section>

      <section class="section" v-else>
        <detail-content-block
          :hotels="priced_hotels"
          :availabilities="availabilities"
          :model_blocks="model_blocks"
          :availability="availability.toString()"
          :hotel="selected_hotel"
        />
      </section>

    </b-container>
  </div>
</template>

<script>
import DetailContentBlock from '@booking/components/views/content-blocks/detail.vue'
import { service_handler } from '@src/services'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'detail-view',

  components: {
    'detail-content-block': DetailContentBlock
  },

  props: {
  },

  data() {
    return {
      priced_hotels: [],  // rinominata altrimenti va in con la chiave 'hotels' dello state per i risultati di ricerca
      availabilities: [],
      model_blocks: [],
    }
  },

  computed: {
    // global state keys
    ...mapState([
      'searching',
      'occupancy',
      'availability',
    ]),
    // module state keys
    ...mapState(
      'livebk', [
        'checkin',
        'checkout',
        'selected_hotel',
        'selected_solution',
        'room_selection'
      ]
    ),
  },

  created() {
  },

  mounted() {
    this.price()
  },

  methods: {

    price() {
      let params = {
        checkin: this.checkin,
        checkout: this.checkout,
        occupancy: this.occupancy,
        hotel_ids: this.selected_hotel.hotel_id
      }

      this.$store.commit('searching', true)

      service_handler(
          'details',
          params
      ).then(
          (success) => {
            this.availabilities = success.data.availabilities
            this.priced_hotels = success.data.hotels

            let model_hotel = []

            success.data.hotels.forEach(hotel => {
              hotel.room_data_parsed = this.parse_room_data(this.availabilities, this.priced_hotels)
              delete hotel.room_data

              model_hotel.push(hotel)
            })
            this.priced_hotels = model_hotel

            this.update_model_blocks()

            this.$store.commit('searching', false)
          },
      )
    },

    parse_room_data(availabilities, hotels) {

      const hotel_id_availability = {}

      availabilities.forEach(avail => {
        hotel_id_availability[avail.hotel_id] = avail.block

        avail.block.forEach(block => {
          block.mealplan_parsed = {
            all_inclusive: {
              included: block.all_inclusive
            },
            breakfast: {
              included: block.breakfast_included
            },
            lunch: {
              included: block.lunch_included
            },
            dinner: {
              included: block.dinner_included
            }
          }
        })
      })

      return hotels.map(hotel => {
        return hotel.room_data.map(room => (
            {
              ...room,
              block: hotel_id_availability[hotel.hotel_id]
                  ? hotel_id_availability[hotel.hotel_id].filter(avail_room => avail_room.room_id === room.room_id)
                  : []
            }
        ))
        .filter(room => room.block.length > 0)
      }).flat()
    },

    update_model_blocks() {
      const create_default_block = () => ({
        incremental_price: {
          quantity: 0,
          charge_price: 0,
          price: 0,
          live_price: 0,
          extra_charges_breakdown: {}
        }
      })

      const selected_solution = this.selected_solution
      const selected_hotel = this.selected_hotel

      if (
        !selected_solution.checkout_payload ||
        selected_hotel.hotel_id !== selected_solution.checkout_payload.hotel_id ||
        !_.isEqual(this.occupancy, selected_solution.checkout_payload.occupancy)
      ) {

        this.model_blocks = this.priced_hotels.map(hotel =>
          hotel.room_data_parsed.map(room =>
              room.block.map(create_default_block)
          )
        )

        this.$store.commit('livebk/room_selection', {})
        this.$store.commit('livebk/selected_solution', {})
      } else {
        this.model_blocks = this.$store.state.livebk.room_selection.model_blocks
      }
    }

  },
}
</script>

<style lang="scss" scoped>
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"carousel",attrs:{"id":_vm.$options.name}},_vm._l((_vm.hotels),function(hotel,hotel_index){return _c('div',{key:_vm._uid+'_carousel_'+hotel_index},[(hotel.hotel_data.hotel_photos.length)?_c('div',[_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal_carousel'),expression:"'modal_carousel'"}],staticClass:"carousel__main"},[_vm._l((hotel.hotel_data.hotel_photos),function(image,image_index){return [(image_index < 6)?_c('div',{key:_vm._uid+image_index,class:'carousel__item carousel__item--' + (image_index+1),style:('background-image:url('+ image.url_original + ')')}):_vm._e()]}),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal_carousel'),expression:"'modal_carousel'"}],staticClass:"carousel__show"},[_c('vb-icon',{staticClass:"carousel__show-icon",attrs:{"name":'gallery',"color":'13131a',"size":14}}),_c('span',{staticClass:"carousel__show-text",domProps:{"innerHTML":_vm._s('Vedi tutte le foto')}})],1)],2),_c('vb-modal',{attrs:{"vb_options":{
            id: 'modal_carousel',
            modal_class: 'carousel__modal',
            dialog_class: hotel.hotel_data.hotel_photos.length > 20 ? 'fullwidth' : '',
            title: hotel.hotel_data.name,
            size: 'xl',
            centered: true,
            hide_footer: true,
          }}},[_c('div',{ref:"swiper-container",refInFor:true,staticClass:"carousel__modal-wrap"},_vm._l((hotel.hotel_data.hotel_photos),function(image,image_index){return _c('div',{key:_vm._uid+image_index,staticClass:"carousel__modal-item"},[_c('a',{staticClass:"carousel__modal-anchor",class:image_index === _vm.current_slide_index && 'carousel__modal-anchor--active',attrs:{"href":image.url_original,"data-pswp-width":_vm.get_pswp_size(image_index, 'width'),"data-pswp-height":_vm.get_pswp_size(image_index, 'height')}},[_c('img',{ref:'img_ref_' + image_index,refInFor:true,staticClass:"carousel__modal-img",attrs:{"src":image.url_original},on:{"load":function($event){return _vm.get_image_size(image_index)}}})])])}),0)])],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
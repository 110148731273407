<template>
  <div class="quick-nav">
    <template v-for="(block, block_index) in blocks">
      <router-link
          class="quick-nav__item"
          :key="_uid+'facilities'+block_index"
          v-if="block.show"
          :to="{ name: 'detail', hash: '#' + block.key }"
      >
        <span class="quick-nav__link" v-html="block.quick_text" />
      </router-link>
    </template>
  </div>
</template>

<script>


export default {
  name: 'description',

  props: {
    blocks: Array,
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

  .quick-nav {
    $this_list: &;

    display: flex;
    align-items: center;
    background-color: $white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;

    &__item {

      padding: 0 20px;
      position: relative;

      &:after {
        content: "";
        width: 1px;
        height: 30%;
        background-color: $gray-200;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      &:first-child {
        border: none;
        padding-left: 0;
        margin-left: 0;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &__link {
      display: block;
      padding: 20px 0;

      /*@at-root #{$this_list}__item:first-child & {
        border-bottom: 2px solid $info;
      }*/

      &:hover {
        text-decoration: underline;
      }
    }

    &__list {



    }
  }

</style>
<template>
  <section class="rooms-carousel" v-if="room.room_photos">
    <div>
      <!--main carousel-->
      <swiper-container init="true" ref="swiper-container" class="rooms-carousel__main">
        <swiper-slide v-for="(image, image_index) in room.room_photos" v-bind:key="_uid + '_main_' + image_index" class="rooms-carousel__item">
          <img class="rooms-carousel__image" :src="image.url_original" alt="" />
        </swiper-slide>
      </swiper-container>

      <!--thumbs carousel-->
      <swiper-container init="true" ref="swiper-thumbs" class="rooms-carousel__thumbs">
        <swiper-slide
          v-for="(image, image_index) in room.room_photos"
          v-bind:key="_uid + '_thumb_' + image_index"
          class="rooms-carousel__item"
          @click="swiper_instance.swiper.slideTo(image_index)"
          :class="{ active: image_index === current_slide_index }"
        >
          <img class="rooms-carousel__image" :src="image.url_max300" alt="" />
        </swiper-slide>
      </swiper-container>

      <!--navigation-->
      <vb-icon :name="'angle_left'" :size="26" class="rooms-carousel__arrow rooms-carousel__arrow--prev" @click="swiper_instance.swiper.slidePrev()" />
      <vb-icon :name="'angle_right'" :size="26" class="rooms-carousel__arrow rooms-carousel__arrow--next" @click="swiper_instance.swiper.slideNext()" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'rooms-carousel',

  props: {
    room: Object,
  },

  components: {
  },

  data() {
    const vm = this
    return {
      current_slide_index: 0,
      swiper_thumbs_params: {
        slidesPerView: 4,
        breakpoints: {
          // da lg in poi
          992: {
            slidesPerView: 8,
          },
        },        
      },
      swiper_params: {
        slidesPerView: 1,
        on: {
          slideChange(event) {
            vm.swiper_thumbs.swiper.slideTo(event.activeIndex)
            vm.current_slide_index = event.activeIndex
          },
        },
      }
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {

    // carousel thumb
    const swiper_thumbs = this.$refs['swiper-thumbs']
    if(swiper_thumbs) {
      Object.assign(swiper_thumbs, this.swiper_thumbs_params)
      swiper_thumbs.initialize()
      this.swiper_thumbs = swiper_thumbs
    }

    // carousel full
    const swiper_instance = this.$refs['swiper-container']
    if(swiper_instance) {
      Object.assign(swiper_instance, this.swiper_params)
      swiper_instance.initialize()
      this.swiper_instance = swiper_instance
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

  .rooms-carousel {
    $this: &;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;

    &__main {
      overflow: hidden;
    }

    &__thumbs {
      margin-top: 6px;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block
      }
    }

    &__item {
      position: relative;

      @at-root #{$this}__main & {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 270px;
        border-radius: 10px;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
          height: 400px;
        }
      }

      @at-root #{$this}__thumbs & {
        cursor: pointer;
        padding-right: 3px;
        padding-left: 3px;
        height: 100px;
        overflow: hidden;
        border-radius: 5px;

        @include media-breakpoint-up(md) {
          height: 90px;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 3px;
          right: 3px;
          bottom: 0;
          transition: $transition-base;
          z-index: 1;
          border: 2px solid transparent;
          border-radius: 5px;
        }
      }

      &.active {
        &:after {
          content: "";
          border-color: $secondary;
        }
      }
    }

    &__image {
      object-fit: cover;
      object-position: center center;
      min-height: 100%;
      min-width: 100%;

      @at-root #{$this}__thumbs & {
        border-radius: 5px;

        &:hover {
          opacity: .6;
        }
      }

      @at-root #{$this}__item.active & {
        opacity: .4;
      }
    }

    &__arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 10;
      cursor: pointer;
      transition: $transition-base;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      @include media-breakpoint-up(sm) {
        bottom: 95px;
      }

      &--prev {
        left: 0;

        ::v-deep {
          svg {
            filter: drop-shadow(-2px 0 0 $white)
          }
        }
      }
      &--next {
        right: 0;

        ::v-deep {
          svg {
            filter: drop-shadow(2px 0 0 $white)
          }
        }
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          background-color: rgba($white, 0.5);
        }
      }


    }
  }

</style>
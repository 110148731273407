var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-content-block"},[_c('div',{staticClass:"detail"},[_c('quick-nav',{staticClass:"detail__navigation",attrs:{"blocks":_vm.detail_blocks}}),_vm._l((_vm.detail_blocks),function(block,key){return _c('div',{key:_vm._uid+key},[(block.show)?_c(block.key,{key:_vm._uid+block+key,tag:"component",attrs:{"hotels":_vm.hotels,"availabilities":_vm.availabilities,"model_blocks":_vm.model_blocks,"availability":_vm.availability,"detail_hotel":_vm.hotel},on:{"check_rooms":_vm.handle_check_rooms}}):_vm._e()],1)}),_c('div',{staticClass:"detail__footer",attrs:{"id":"recap"}},[(_vm.check_rooms.disabled ||
              !_vm.get_availability(_vm.selected_solution.checkout_payload.live_price).can_go_to_checkout)?_c('vb-button',{attrs:{"vb_options":{
          text: 'Continua',
          variant: 'info',
          icon_disabled: 'lock',
          disabled: true
        }}}):_c('div',[(!_vm.is_mobile())?_c('card-recap',{staticClass:"detail__recap",attrs:{"solution":_vm.selected_solution}}):_vm._e(),_c('custom-link',{attrs:{"route_name":'checkout',"wrap":'a',"before_navigation":() => {
            this.$store.commit('livebk/hotel_data', _vm.hotels[0].hotel_data)
          }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(!_vm.is_mobile())?_c('vb-button',{attrs:{"vb_options":{
                text: 'Continua',
                variant: 'info',
              }}}):_c('div',{staticClass:"detail__cart"},[_c('p',{staticClass:"detail__cart-title",domProps:{"innerHTML":_vm._s('Completa la prenotazione!')}}),_c('div',{staticClass:"detail__cart-wrap"},[_c('div',{staticClass:"detail__cart-text"},[_c('span',{domProps:{"innerHTML":_vm._s(Object.values(_vm.selected_solution.checkout_payload.blocks).reduce((total, item) => total + item, 0) + ' unità selezionat' + (Object.values(_vm.selected_solution.checkout_payload.blocks).reduce((total, item) => total + item, 0) > 1 ? 'e' : 'a') + ' per € ' + _vm.selected_solution.checkout_payload.live_price)}}),_c('span',{domProps:{"innerHTML":_vm._s('include tasse e costi')}})]),_c('vb-icon',{staticClass:"detail__cart-icon",attrs:{"name":'angle_small_right',"color":'fff',"size":32}})],1)])]},proxy:true}])})],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="detail-content-block">

    <div class="detail">

      <quick-nav class="detail__navigation" :blocks="detail_blocks" />

      <div v-for="(block,key) in detail_blocks" v-bind:key="_uid+key">
        <component
          v-if="block.show"
          v-bind:key="_uid+block+key"
          :is="block.key"
          :hotels="hotels"
          :availabilities="availabilities"
          :model_blocks="model_blocks"
          :availability="availability"
          :detail_hotel="hotel"
          @check_rooms="handle_check_rooms"
        />
      </div>

      <div id="recap" class="detail__footer">
        <vb-button
          v-if="check_rooms.disabled ||
                !get_availability(selected_solution.checkout_payload.live_price).can_go_to_checkout"
          :vb_options="{
            text: 'Continua',
            variant: 'info',
            icon_disabled: 'lock',
            disabled: true
          }"
        />
        <div v-else>
          <!-- desktop -->
          <card-recap v-if="!is_mobile()" class="detail__recap" :solution="selected_solution" />

          <custom-link :route_name="'checkout'" :wrap="'a'"
            :before_navigation="() => {
              this.$store.commit('livebk/hotel_data', hotels[0].hotel_data)
            }"
          >
            <template #content>

              <!-- desktop -->
              <vb-button v-if="!is_mobile()" :vb_options="{
                  text: 'Continua',
                  variant: 'info',
                }"
              />

              <!-- mobile -->
              <div v-else class="detail__cart">
                <p class="detail__cart-title" v-html="'Completa la prenotazione!'" />
                <div class="detail__cart-wrap">
                  <div class="detail__cart-text">
                    <span v-html="Object.values(selected_solution.checkout_payload.blocks).reduce((total, item) => total + item, 0) + ' unità selezionat' + (Object.values(selected_solution.checkout_payload.blocks).reduce((total, item) => total + item, 0) > 1 ? 'e' : 'a') + ' per € ' + selected_solution.checkout_payload.live_price" />
                    <span v-html="'include tasse e costi'" />
                  </div>
                  <vb-icon class="detail__cart-icon" :name="'angle_small_right'" :color="'fff'" :size="32" />
                </div>
              </div>

            </template>
          </custom-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuickNav from "@booking/components/blocks/detail/quick-nav.vue"
import Heading from "@booking/components/blocks/detail/heading.vue"
import Carousel from "@booking/components/blocks/detail/carousel.vue"
import Description from "@booking/components/blocks/detail/description.vue"
import Facilities from "@booking/components/blocks/detail/facilities.vue"
import Policies from "@booking/components/blocks/detail/policies.vue"
import Info from "@booking/components/blocks/detail/info.vue"
import Rooms from "@booking/components/blocks/detail/rooms.vue"
import CardRecap from "@booking/components/blocks/card-recap.vue"
import { mapState } from 'vuex'

export default {
  name: "detail-content-block",

  components: {
    'quick-nav': QuickNav,
    'heading': Heading,
    'carousel': Carousel,
    'description': Description,
    'facilities': Facilities,
    'policies': Policies,
    'info': Info,
    'rooms': Rooms,
    'card-recap': CardRecap
  },

  props: {
    hotels: Array,
    availabilities: Array,
    model_blocks: Array,
    availability: String,
    hotel: Object,
  },

  data() {
    return {
      detail_blocks: [],
      check_rooms: {
        disabled: true,
        label: '',
        variant: ''
      }
    }
  },

  computed: {
    ...mapState(
      'livebk', [
        'selected_solution',
      ]
    ),
  },

  created() {
  },

  mounted() {
    this.detail_blocks = [
      {
        key: "heading",
        show: true,
        quick_text: 'Panoramica'
      },
      {
        key: "carousel",
        show: true,
        quick_text: 'Gallery'
      },
      {
        key: "description",
        show: true,
        quick_text: 'Descrizione'
      },
      {
        key: "rooms",
        show: true,
        quick_text: 'Camere e prezzi'
      },
      {
        key: "facilities",
        show: true,
        quick_text: 'Servizi'
      },
      {
        key: "policies",
        show: true,
        quick_text: 'Leggi prima di prenotare'
      },
      {
        key: "info",
        show: true,
        quick_text: 'Da sapere'
      },
    ]
  },

  methods: {
    handle_check_rooms(result) {
      this.check_rooms = result;
    }
  },
}
</script>

<style lang="scss" scoped>

  .detail {

    &__navigation {
      margin-bottom: $grid-gutter-width*2;
      display: none;

      @include media-breakpoint-up(xl){
        display: flex;
      }
    }

    &__recap {
      margin-bottom: $grid-gutter-width*2;
    }

    &__cart {
      background-color: $primary;
      color: $white;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 15px;
      position: relative;

      &-title {
        font-weight: $vb-font-weight-semibold;
        font-size: $font-size-lg;
        margin-bottom: 5px;
      }

      span {
        display: block;

        &:first-child {
          color: $secondary;
        }

        &:last-child {
          font-size: $font-size-sm;
          opacity: .5;
        }
      }

      &-icon {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
      }
    }

    &__footer {
      @extend %section;
      @extend %section_margin;
      text-align: center;
      margin-bottom: $grid-gutter-width*3;

      @include media-breakpoint-up(md){
        text-align: right;
        margin-bottom: inherit;
      }

      .btn {
        width: 200px;
      }

      ::v-deep {
        .custom-link > * {
          display: block;
        }
      }
    }
  }

</style>